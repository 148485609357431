module.exports = {
	system: {
		toSet:'설정으로 이동',
		coinRanking:'코인랭킹',
		ranking:'순위',
		dailyProfitTakingFrequency:'일일 익절 빈도',
		volatilityActivity:'변동성 활동도',

		dibu_gua:'핀포인트 트레이딩',
		smartGrid:'계약 스마트 그리드',
		vip2:'노드',
		vip3:'커뮤니티',
		vip4:'노조',
		BBI:'상확지',
		toucun: '포지션',
		duocang: '롱 포지션',
		kongcang: '숏 포지션',
		zcc: '총 포지션',
		kcl: '개설량',
		zdcw: '최대 포지션',
		double: '더블 아비트라지',
		liquidation:'청산 방지',
		wave_f_x_double: '스마트 파도',
		zytj: '손익 중단 조건',
		bcsz: '추가 매수 설정',
		bcje: '추가 매수 금액',
		marting_d: '고정 가격 마틴',
		wave_x: '스마트 파도',
		zhuan_shouxufei: '송금 수수료는',
		shouxufei_danwei: '/ 건',
		zhuanzhangjilu: '송금 기록',
		tibijilu: '출금 기록',
		unlock_price: '포착 자금',
		InOperation: '운영 중',
		Closed: '종료',
		AntiRiotFall: '방지 폭락',
		CycleTime: '시간 주기',
		DeclineRange: '하락 폭',
		UnravelingMode: '풀림 모드',
		jinri: '오늘:',
		shouyipaihangbang: '수익 랭킹',
		tuichudenglu: '로그아웃',
		kaitongvipxiangfuli: 'VIP 멤버쉽 혜택',
		lijikaitong: '즉시 개통',
		download: '앱 다운로드',
		AccountLogin: '계정 로그인',
		Successqinggua: '미체결 주문 정리 완료',
		failqinggua: '미체결 주문 정리 실패',
		Qingliguadan: '미체결 주문 정리',
		wave_f_coin: '증코 전략',
		bindEmailSuccess: '이메일 바인딩 완료',
		quedingbangding: '바인딩 확인',
		qingshuruyouxiang: '이메일을 입력하세요',
		qingshuruyouxiang_new: '새 이메일을 입력하세요',
		qingshuruyouxiangzhanghao: '이메일 계정을 입력하세요',
		duo: '다양한',
		kong: '빈도',
		allchecked: '모두 선택',
		bian1: 'Binance',
		huobi1: 'Huobi',
		zhiyingjiage: '손익 가격',
		manual_f_long: '수동 전략 많음',
		manual_f_short: '수동 전략 공백',
		beishu: '곱하기',
		wangge_x: '해체 마틴',
		wave_f_long: '파도 헤지 다양한',
		wave_f_short: '파도 헤지 빈도',
		look_all: '모두보기',
		weighted_share_reward_raise: '글로벌 분배',
		yuerbuzu: '잔액 부족, 충전하세요!',
		shangxueyuan: '상경대학',
		insufficient_gas: '연료비 부족',
		not_valid_membership: '사용자가 유효한 기간 회원이 아닙니다',
		wrong_params: '잘못된 매개변수',
		failed_to_save: '설정 저장 실패',
		check_params: '매개변수를 확인하세요',
		empty_bot_id: '봇 ID가 비어 있습니다',
		wrong_bot_id: '잘못된 봇 ID',
		server_error: '서버 오류',
		zhisunjiage: '손절 가격',
		dangqianzijin: '현재 자금',
		dangqianchicang: '현재 보유',
		queshaojiaoyimiyao: '거래소 개인 키가 누락되었습니다. 지금 바로 바인딩 하시겠습니까?',
		lijibangdin: '즉시 바인딩',
		jiaoyileixin: '거래 유형',
		im_teacher: '나는 선생님이다',
		add_lianghua: '양화 추가',
		ljyjsr: '누적 수수료 수입',
		xianshimingcheng: '표시 이름',
		xuanzhejiaoyisuo: '거래소 선택',
		jiaoyifangshi: '거래 방식',
		zhanghuzongzichan: '계정 총 자산',
		weikaiqi: '미개시',
		jinxinzhong: '진행 중',
		shenhezhong: '심사 중',
		yichehui: '철회',
		yijujue: '거절',
		xiugaixinxi: '정보 수정',
		kaiqi: '시작',
		tingzhilianghua: '양화 중지',
		chehuishenhe: '심사 철회',
		quanbutingzhi: '모두 중지',
		quedingyaokaiqilianghuama: '양화를 시작하시겠습니까?',
		queding: '확인',
		zanshibuyao: '일시 중지',
		quedingyaotingzhilianghuama: '양화를 중지하시겠습니까?',
		tingzhihoubunengjixu: '중지 후에는 더 이상 지시할 수 없습니다',
		quedingyaochehuishenqingma: '신청을 철회하시겠습니까?',
		quedingyaotingzhiall: '모든 양화를 중지하시겠습니까?',
		success_che: '철회 성공',
		success_stop: '중지 성공',
		success_open: '시작 성공',
		tuoguanxiangqing: '위탁 세부 정보',
		putongVIP: '일반 회원',
		xiugaichushituoguanzijin: '초기 위탁 자금 수정',
		hdsy: '수익 획득',
		syfd: '수익률',
		mrsy: '매일 수익',
		xiugaichenggong: '수정 성공',
		my_tuoguanxiangqing: '내 수탁 상세 정보',
		tuoguancishu: '수탁 횟수',
		daidanjiner: '대단 금액',
		nidezhanghaohaiweibangding: '귀하의 계정은 아직 연결되지 않았습니다.',
		pingtaizanwufashiyong: '플랫폼에서 수탁 기능을 사용할 수 없습니다.',
		qubangding: '연결하러 가기',
		xiugailianghua: '양화 수정',
		qingshuruxianshimingcheng: '표시 이름을 입력하세요.',
		shoufeibili: '수수료 비율',
		qingshurushoufeibili: '수수료 비율을 입력하세요.',
		zhanghuzongzichan: '계정 총 자산',
		qingshuruzhanghuzongzichan: '계정 총 자산을 입력하세요.',
		tijioashenqing: '신청 제출',
		shoufeilvbuhefa: '유효하지 않은 수수료 비율입니다.',
		fxtishi: '위험 경고',
		agree: '동의',
		refuse: '거절',
		Contract: '장',
		xiugaifangxiang: '방향 수정, 기존 보유 포지션 청산됩니다',
		celue: '전략',
		zuoduo: '매수',
		zuokong: '매도',
		zuodanfangxiang: '매매 방향',
		xianhuojiaoyi: '현물 거래',
		heyuejiaoyi: '선물 거래',
		kaifazhong: '개발 중',
		new_price: '최신가',
		chengweilaoshi: '선생님 되기',
		woyaotuoguan: '투자 위탁하고 싶습니다',
		wodetuoguan: '나의 투자 위탁',
		gensuizhe: '팔로워',
		onedayshouyi: '24시간 수익률',
		fenchengbili: '분배 비율',
		zongdaidanrenshu: '총 보유자 수',
		nianhuashouyi: '연환산 수익률',
		jinrishouyi: '금일 수익 USDT',
		leijishouyi: '누적 수익 USDT',
		zhengzaijinxin: '진행 중',
		yijieshu: '종료됨',
		chushituoguanzijin: '초기 위탁 자금',
		huodeshouyi: '수익 획득 USDT',
		tuoguanshijian: '위탁 시간',
		xiugaizijin: '자금 수정',
		wodeleijishouyi: '나의 누적 수익',
		wodetuoguancishu: '나의 투자 위탁 횟수',
		xiangqing: '상세 정보',
		chushizijin: '초기 자금',
		shouyilv: '수익률',
		ti_tingzhi: '위탁 중지 후에는 선생님의 거래를 더 이상 따르지 않습니다!',
		tuoguanzijin: '투자 위탁 자금',
		zuidakeyongyue: '최대 사용 가능 잔액',
		qingshuru: '입력해주세요',
		quanbutuoguan: '전부 위탁',
		querentuoguan: '위탁 확인',
		cangwei: '포지션',
		jinsantianshouyi: '최근 3 일 화장품 수익',
		yuqinianhuashouyilv: '예상 연간 수익률',
		zhengzaidaidanrenshu: '현재 무대 매수 인원',
		piliangtankuang: '이미 선택된 모든 코인에 대해서만 작동합니다',
		transfer_accounts: '전송 성공',
		refund: '환불',
		global_reward_raise: '글로벌 보상 증가',
		trusteeship_brokerage_reduce: '위탁 수수료 차감',
		trusteeship_brokerage_add: '위탁 수수료',
		xianhuo_gas: '연료비',
		deposit: '입금',
		team_reward: '팀 보상',
		direct_promote: '직접 추천 보상',
		indirect_promote: '간접 추천 보상',
		flat_level_reward_raise: '플랫 레벨 보상 증가',
		buy_active_code: '회원카드 구매',
		withdraw: '출금',
		transfer: '전송',
		system_deposit: '시스템 입금',
		frozen_asset: '동결 자산',
		transfer_out_fee: '전송 수수료',
		withdraw_deny: '출금 거절',
		Delete: '삭제',
		sure_to_delete: '삭제 하시겠습니까?',
		delete_ok: '삭제 성공!',
		delete_no: '삭제 취소!',
		SetAPI: 'API 설정',
		DeleteAPI: 'API 삭제',
		bucangbeishu: '보상 배율',
		marting: '스마트 마틴',
		wangge_f: '스마트 마틴',
		bottom: '하락 추적',
		wave: '웨이브 그리드',
		wave_f: '웨이브 그리드',
		Single: '한 번 순환',
		Continuous: '연속 순환',
		APIAuthorization: 'API 인증',
		// -----20221128 start----
		okx: 'OKX 개통',
		huobi2: 'Huobi 개통',
		tip: '힌트',
		warehouseClear: '정말 한 번에 모두 정리하시겠습니까?',
		orderClear: '주문 정리를 확인하시겠습니까?',
		ConfirmSwap: '환전 확인',
		ConfirmSwapDialog: '환전을 확인하시겠습니까?',
		beyondBalance: '사용 가능한 잔액을 초과합니다',
		swapSuc: '환전 성공!',
		viewAll: '전체보기',
		Accountassets: '계좌 자산',
		openExchange: '거래소 계정 개통',
		systemTitle: '디지털 자산 스마트 관리 시스템',
		FaceToFaceShare: '얼굴 대 얼굴 코드 스캔 초대',
		IOSdown: 'IOS 다운로드',
		androidDown: '안드로이드 다운로드',
		iosDownErr: 'IOS 버전 곧 출시 예정',
		tutorial: '튜토리얼',
		setSuc: '설정 성공',
		representative: '영업 대표',
		partner: '파트너',
		closeAll: '한 번에 모두 닫기',
		openAll: '한 번에 모두 열기',
		AntiRiotFall: '폭락 방지',
		CycleTime: '시간 주기',
		UnravelingMode: '탈피 모드',
		fixedStopLoss: '고정 손절',
		floatStopLoss: '유동 손절',
		InOperation: '운영 중',
		Closed: '닫힘',
		unlock_price: '탈피 자금',
		routine: '일반 그리드',
		priceDB: '가격',
		priceDBWave: '가격 범위 스마트 설정',
		priceDBRoutine: '가격 범위 수동 설정',
		orderDB: '주문',
		orderDBWave: '극소 주문 자금 사용률 높음',
		orderDBRoutine: '주문 자금 소모 거대',
		plungeDB1: '폭락',
		plungeDBWave1: '폭락 방지 모니터링 모듈 자동으로 위험 감지 및 자동 시작/중지',
		plungeDBRoutine1: '급락에 대응하는 메커니즘이 없습니다',
		plungeDB: '폭락',
		plungeDBWave: '방폭락 감시 모듈은 위험을 자동으로 감지하고 시작/중지를 자동으로 제어하며, 일일 주문 한도가 있습니다.',
		plungeDBRoutine: '극단적인 시장 상황에 대처할 메커니즘이 없습니다',
		geoDB1: '등비 등차',
		geoDBWave1: '등비 등차 그리드를 지원하며 지수 등급 축소 그리드도 지원합니다',
		geoDBRoutine1: '등비 등차의 일반 가격 그리드 덮개 또는 감량 논리',
		geoDB: '그리드 너비',
		geoDBWave: '자산의 ATR 지표 및 다양한 기타 요소를 기반으로 동적이고 지능적으로 생성되는 그리드 너비로, 자본 활용도가 더 높습니다',
		geoDBRoutine: '등비 등차의 전통적인 가격 그리드',
		BBIDB:'가격 변동 예측',
		BBIDBWave1:'"라이트닝 불-베어 지수"를 소개합니다. 이동 평균, 거래량, ATR, MACD, RSI 등 다차원 지표를 통합하여 가격 상승과 하락의 확률을 예측할 수 있습니다',
		BBIDBWave:'"라이트닝 불-베어 지수"를 소개합니다. 주가 이동평균, 거래량, ATR, MACD, RSI 등 다양한 다차원 지표를 통합하여 지능적으로 상승 및 하락 방향을 전환할 수 있습니다',
		BBIDBRoutine1:'불-베어 지수가 없으면 시장 트렌드를 예측할 수 없습니다',
		BBIDBRoutine:'불-베어 인덱스 없이는 시장 추세의 방향을 예측할 수 없습니다',
		uncoatDB: '스마트 해지 해제',
		uncoatDBWave: '"스마트 해지 해제 알고리즘"을 새롭게 도입하여 "갇힌 주문"을 지능적으로 식별하고 자동으로 마팅게일 배팅 방식으로 전환하여 이익을 위한 해지 해제 능력을 강화하였습니다',
		uncoatDBRoutine: '해지 해제 또는 포지션 축소 로직이 없습니다',

		saveImg: '앨범에 저장',

		// res err
		gasInsufficient: '사용자 연료비 부족',

		// -----20221128 end----

		// -----20210414 start----
		transfer_in: '전송 내부로',
		transfer_out: '전송 외부로',
		SuccessfulPurchase: '구매 성공!',
		AvailableBalance: '최대 사용 가능한 잔액',
		Processing: '처리 중...',
		Stoping: '중지 중...',
		Clearanceing: '청산 중...',
		GetParameters: '매개변수 가져오는 중...',
		qidong: '시작 중...',
		qidongsuccess: '시작 성공',
		FailedAcquireRobot: '로봇 가져오기 실패',
		Loading: '로딩 중...',
		SaveParametersSucceeded: '매개변수 저장 성공!',
		SaveParametersFailed: '매개변수 저장 실패!',
		SaveStartSuccessfully: '저장하고 성공적으로 시작!',
		CannotEmpty: '매개변수는 비워 둘 수 없습니다!',
		robot: '로봇',
		StopSuccess: '정지 성공',
		PleaseSetTogetInitializationParameters: '설정을 클릭하여 초기화 매개 변수를 가져옵니다.',
		OnceMaxAction20: '한 번에 20 개의 거래 쌍을 조작 할 수 없습니다.',
		SelectOneMore: '최소한 하나의 거래 쌍을 선택하십시오.',
		successNumber: '성공 수',
		failNumber: '실패 수',
		SaveingParametersbatch: '매개 변수 일괄 저장 중 ...',
		errorWithdrawAmount: '인출 금액이 올바르지 않습니다.',
		// -----20210414 end----
		// -----20210413 start----
		SelectPosition: '포지션 선택',
		Profits: '%수익금',
		UserHostingAgreement: '《사용자 호스팅 계약》',
		TrusteeshipRiskyShouldBeCaution: '호스팅은 위험이 있으므로 신중하게 사용하십시오.',
		ServiceRights: '서비스 권리',
		enteryourMobilenumber: '휴대폰 번호를 입력하세요!',
		PleaseCheckUserAgreement: '《사용자 계약》을 선택하십시오.',
		// RegistrationSuccessfulReturntoLogin: '등록 성공! 로그인 페이지로 돌아가 로그인하십시오!',
		RegistrationSuccessfulReturntoLogin: '등록 성공!',
		PleaseEnterYourUsername: '사용자 이름을 입력하십시오.',
		AnnouncementDetails: '공지 사항 세부 정보',
		PasswordLogin: '비밀번호 로그인',
		VerificationCodeLogin: '인증 코드 로그인',
		passwordMin8: '비밀번호를 입력하지 않거나 8 자리 이하일 수 없습니다.',
		// ------20210413 [end]-----
		// -----20200409 start----
		APIset: 'API 설정',
		codeHasSend: '다음으로 인증 코드가 보내기:',
		enterAPIKey: 'API KEY를 입력하십시오.',
		enterSecretKey: 'Secret KEY를 입력하십시오.',
		enterPassphrase: 'Passphrase를 입력하십시오.',
		// setWord1: '받은',
		// setWord1_2: 'API KEY를 동기화하기 위해 사용됩니다.',
		// setWord2: '당신의',
		// setWord3: '즉시 개설',
		setWord1: '{exchange}에 들어가서 API 메뉴를 통해 필요한 정보를 만든다',
		setWord2: '만든 정보를 위의 입력창에 복사합니다',
		setWord3: '확인을 클릭하고 app 홈페이지 하단에 있는 자산을 확인합니다',
		walletRecord: '지갑 레코드',
		fuelFee: '연료 비용',
		totalReward: '양자화 총 수익',
		today: '오늘의 수익',
		total: '총 :',
		addUp: '누적 수익',
		toBeVIP: '즉시 VIP가되십시오',
		setupMarting: '마틴 설정',
		Onceloop: '한 번의 루프',
		ci: '번',
		cycle: '주기',
		extentContinuousDecline: '연속 하락 폭',
		MemberClub: '멤버 클럽',
		deadline: '마감일',
		youarenotVip: '아직 회원이 아닙니다',
		handlingMembership: '회원 가입 처리',
		yes: '예',
		chongbiAddress: '입금 주소',
		choosebi: '화폐 선택',
		reciceMoneyAddress: '수취인 주소',
		reciveAccount: '수취인 계정',
		reciveAccountName: '수취인 계정명',
		tibiConfirmInfo: '출금 정보 확인',
		RiskStatement: '위험성 경고',
		riskTipsText: {
			one: '1. 출금 주소 정보를 꼼꼼하게 확인하십시오. 출금이 완료되면 자산을 회수할 수 없습니다.',
			two: '2. 불법 모금, 사기 등 위법한 행위에 주의하십시오. "블록체인 알고리즘 수익 채굴"과 같은 사기와 "배당 조작"과 같은 덫에 걸리지 않도록 조심하십시오.',
			three: '3. 투자에 대한 공동 작업으로 주장하는 것은 모두 사기입니다. 공식 검증 채널을 클릭하여 공식 채널인지 확인하십시오.',
			four: '4. 디지털 자산 투자는 고위험 행위입니다. 사기 코인, 모조 코인 등 투자 프로젝트를 식별하십시오.',
			five: '5. 궁금한 점이 있으시면 도움말 센터에서 고객 서비스와 연락하십시오.',
		},
		tibiAddressError: '출금 주소가 올바르지 않습니다',
		checkNetwork: '네트워크를 확인하십시오',
		SecurityVerification: '보안 검증',
		verificationCode: '인증 코드',
		PasswordInput: '비밀번호 입력',
		VerificationCodeSentSuccess: '인증 코드 전송 성공',
		changeLoginPass: '로그인 비밀번호 변경',
		setTransferPass: '거래 비밀번호 설정',
		setDefaultExchange: '기본 거래소 설정',
		inputLoginPwd: '로그인 비밀번호를 입력하십시오',
		changePwd: '비밀번호 변경',
		copyVersion: '버전 번호',
		weiXin: '위챗',
		trusteeship: '위탁',
		trusteeship_close: '위탁 중지',
		trusteeshipClose: '현재 패키지를 닫으시겠습니까?',
		trusteeshipClose_yes: '예',
		trusteeshipClose_no: '아니오',
		trusteeshipClose_success: '패키지 종료 성공!',
		trusteeshipOpen_success: '패키지 개시 성공!',
		trusteeshipPlease_check: '사용자 위탁 계약에 체크하세요!',
		uniswap: 'Uniswap',
		dealNow: '즉시 거래',
		noRobot: '로봇 없음',
		startNow: '즉시 시작',
		oneButtonReplenishment: '원 클릭 보충 (USDT)',
		oneButtonReplenishment1: '원 클릭 보충 (장)',
		cao_num: '보유한 수량 초과',
		ReplenishmentQuantity: '보충 수량:',
		SelloutQuantity: '판매 수량:',
		MartinDetails: '마틴 상세 정보',
		BottomTrackingDetails: '바닥 추적 상세 정보',
		stopRobotFirst: '로봇을 먼저 중지하세요',
		SuccessReplenish: '보충 성공',
		SuccessSellout: '판매 성공',
		failReplenish: '보충 실패',
		failSellout: '판매 실패',
		SuccessClearance: '청산 성공',
		failClearance: '청산 실패',
		OrderStatus: '주문 상태',
		helpGuide: '도움말 가이드',
		resetPassword: '비밀번호 재설정',
		nextStep: '다음 단계',
		enterMobileNumber: '전화번호를 입력하세요',
		EnterReceivedVerificationCode: '받은 인증 코드 입력',
		Resend: '다시 보내기',
		// -----20200409 end----
		historyRecord: '거래 기록',
		descriPtion: '설명',
		filterType: '필터 유형',
		MyProfit: '내 이익',
		todayProfit: '오늘의 이익',
		AccumulatedProfit: '누적 이익',
		order: '주문',
		date: '날짜',
		switch_pack: '패키지 교환',
		DealFor: '거래 대상',
		running: '진행 중',
		stop: '중지됨',
		all: '모두',
		unconfig: '인기',
		manualBuchang: '수동 보충',
		manualMaichu: '수동 판매',
		savefail: "저장 실패",
		exchange: "거래소",
		Profit: '이익',
		Activation1code: '활성화 코드 (사용 완료)',
		MyActivationCode: '내 활성화 코드',
		PurchaseQuantity: '구매 수량',
		payment: '지불',
		PurchaseActivationCode: '활성화 코드 구매',
		BuyNow: '지금 구매',
		setup: '설정',
		start_up: '시작',
		stop_it: '정지',
		assets: '자산',
		ChainType: '체인 종류',
		Receivingaddress: '수신 주소',
		copy: '복사',
		explain: '위의 주소로 USDT-TRC20 이외의 자산을 입금하지 마십시오. 그렇지 않으면 자산을 반환할 수 없습니다.',
		cancel: '취소',
		title: '전략 상세 정보',
		PositionAmount: '보유 포지션 금액',
		AveragePositionPrice: '보유 포지션 평단가',
		Numberofpositionsfilled: '보유 포지션 보충 횟수',
		PositionQuantity: '보유 포지션 수량',
		CurrentPrice: '현재 가격',
		Up: '수익률',
		StrategyRelated: '전략 관련',
		estimatedMargin:'예상 증거금',
		OpeningAmount: '진입 금액',
		Replenishmenttimes: '보충 횟수',
		Profitmargin: '수익 기준가 대비 수익률',
		zhiyingcishu: '수익 기준가 도달 횟수',
		OneProfitmargin: '한 거래의 수익 기준가 대비 수익률',
		Suofanglv: '폭 축소율',
		Zuidabucangcishu: '최대 보충 횟수',
		Wanggekuandu: '그리드 폭',
		Bucangjinersuofanglv: '보충 금액 축소율',
		gangganbeishu: '레버리지 배수',
		Profitstopcallback: '수익 기준가 도달 후 콜백',
		Marginofreplenishment: '보충 폭',
		Makeupcallback: '보충 후 콜백',
		Horizontalpushornot: '가로 푸시 여부',
		pingtuifudu: '평타 폭',
		shoucibucang: '첫번째 보충',
		di2cibucang: '두 번째 보충',
		di3cibucang: '세 번째 보충',
		di4cibucang: '네 번째 보충',
		di5cibucang: '다섯 번째 보충',
		di6cibucang: '여섯 번째 보충',
		di7cibucang: '일곱 번째 보충',
		di8cibucang: '여덟 번째 보충',
		di9cibucang: '아홉 번째 보충',
		di10cibucang: '열 번째 보충',
		di11cibucang: '11 번째 보충',
		di12cibucang: '12 번째 보충',
		di13cibucang: '13 번째 보충',
		Cyclesetting: '주기 설정',
		DeclineRange: '하락폭',
		Policyoperation: '전략 운영',
		Policysettings: '전략 설정',
		Clearingandselling: '청산 판매',
		Wavegrid: '파도 그리드',
		article: '뉴스',
		WelcometoRainbow: '환영합니다.',
		Passwordlogin: '비밀번호 로그인',
		SMSlogin: '인증 코드 로그인',
		Forgotpassword: '비밀번호를 잊으셨나요?',
		LogintoagreeUserAgreement: '《사용자 협의 》 로그인시 동의합니다，《사용자 협의》',
		Obtain: '얻다',
		Confirmpasswordagain: '비밀번호 다시 확인',
		registered: '등록',
		invitefriends: '친구 초대',
		home: "홈",
		vcode: "인증 코드",
		Spot: '현물',
		USDTcontract: 'USDT 계약',
		// Currency-basedcontract: '기초화폐 계약',
		CurrencyBasedcontract: '기초화폐 계약',
		Bottomloop: '하단 루프',
		Position: '보유 포지션',
		Floating: '미실현 손익',
		Thepositionhasbeencovered2times: '2회 매수완료',
		Batchsettings: '일괄 설정',
		Batch_stop: '일괄 중지',
		Bulk_clearing: '일괄 청산',
		Usedusdt: '사용한 USDT',
		Usdtavailable: '가용',
		AccountassetsUSDT: '계정 자산 (USDT)：',
		Topup200usdt: '200USDT 입금',
		activation: '활성화',
		ImmediatelyactivatetheRainbowintelligentquantitativerobot: '레인보우 인텔리전트 양적 로봇 즉시 활성화',
		Todaysearnings: '오늘 수익',
		Teamtodaysearnings: '팀 오늘 수익',
		DirectMembership: '직접 추천 회원',
		DirectMembershiplist: '직접 추천 회원 목록',
		Directpushmember: '직접 추천 회원',
		activated: '활성화됨(명)',
		inactivated: '비활성화됨(명)',
		Directpushtotalrevenue: '직접 추천 총 수익',
		Directlypushtodaysearnings: '직접 추천 오늘 수익',
		Numberofteam: '팀 인원수',
		Totalteamrevenue: '팀 총 수익',
		sequence: '순서',
		accountnumber: '계좌 번호',
		Directpush: '직접 추천',
		Team: '팀',
		save: "저장",
		Todayrevenue1: '오늘 수익',
		Todayrevenue: '오늘 수익',
		Totalrevenue: '총 수익',
		Orderid: '주문 ID ',
		Cumulativeincomedaily: '누적 수익 (매일)',
		Buyin: '매수',
		TransactionRecord: '거래 기록',
		Sell: '매도',
		Thenumberoftransactions: '체결 수량',
		Averagetransactionprice: '평균 거래 가격',
		transactionfee: '거래 수수료',
		Handlingfee: '수수료',
		Log: '로그',
		OperationID: '작업 ID',
		News: '뉴스',
		Notice: '알림',
		news: '소식',
		Invitefriendstoenjoya30bonus: '친구 초대하여 30% 보너스 혜택 누리기',
		sendinvitationTofriends: '친구에게 초대장 보내기',
		Friendscompleteregistrationandactivation: '친구가 등록 및 활성화를 완료하면',
		Getrewardsinproportion: '해당하는 비율의 보상 받기',
		Myinvitationlink: '나의 초대 링크',
		Myinvitationcode: '나의 초대 코드',
		Generateinvitationposter: '초대 포스터 생성',
		Popular: '인기',
		Pleaseentercurrency: '통화를 입력해주세요',
		searchfor: '검색',
		Batchclearance: '일괄 청산',
		Bulkreplenishment: '일괄 매수',
		Batchstart: '일괄 시작',
		MotherboardMainarea: '메인 보드 - 메인 영역',
		InnovationZone: '혁신 지역',
		parameter: '매개 변수',
		Cycleway: '사이클 방식',
		Saveandstart: '저장 및 시작',
		Withdraw: '출금',
		Securitycenter: '보안 센터',
		tool: '도구',
		Helpcenter: '도움말 센터',
		aboutus: '회사 소개',
		dropout: '로그아웃',
		Helpdescription: '도움말 설명',
		HelloIamXiaoRcanIhelpyouYoucanclickonthecorrespondingquestiontounderstandtheworkingprincipleofRainsintelligentquantitativerobot: '안녕하세요, 저는 샤오 R입니다. 도움이 필요하시면 클릭하여 Rain의 지능형 양적 로봇 작동 원리를 이해할 수 있는 해당 질문을 클릭하십시오.',
		NoviceOperationGuide: '초보자 운용 가이드',
		Rainsstrategyparameterfunctionsettinginstructions: 'Rain의 전략 매개 변수 기능 설정 지침',
		Rainsrecommendationrewardmechanism: 'Rain의 추천 보상 메커니즘',
		Aboutrates: '수수료에 대하여',
		Aboutdepositandwithdrawalfees: '입금 및 출금 수수료에 대하여',
		Chainname: '체인 이름',
		PleasedonotdepositanynonTRC20USDTassetstoheaboveaddressotherwisetheassetswillnotberetrieved: '상기 주소에 TRC20_USDT 이외의 자산을 입금하지 마십시오. 그렇지 않으면 자산을 회수할 수 없습니다.',
		Minimumwithdrawalamount10: '최소 출금 금액 10',
		Minimumwithdrawalamount: '최소 출금 금액',
		zuyi: '참고 : Follow 플랫폼의 최소 인출 금액은',
		shouxufei: '출금 수수료는',
		shouxufei_danwe2: '/ 건입니다',
		Arrivalquantity: '도착 수량',
		Seagridy: '해 그리드',
		Continuousloop: '연속 루프',
		begin: '시작',
		AlreadyhaveanaccountTologin: '계정이 이미 있으신가요? 로그인하러 가기',
		YouNeedEmail: "인출 거래 등을 위해 이메일을 먼저 설정해주세요",
		email: '이메일',
		strategy_setup: '전략 설정',
		defaultExchange: '기본 거래소',
		defaultVcode: '기본 인증 코드',
		defaultLang: '기본 언어',
		VIPmember: 'VIP 멤버',
		vcode_title: '기본 인증 코드 설정',
		sms: '문자',
		vcode_msg: '출금 및 거래에는 인증 코드가 필요합니다. 기본 인증 코드 전송 방법을 선택하세요.',
		savesucc: '저장되었습니다',
		defaultExchange_msg: '가장 자주 사용하는 거래소를 선택하세요',
		huobi: '화폐',
		bian: 'Binance',
		OKEx: 'OKX',
		lang: '언어 설정',
		en: '영어',
		zh: '중국어',
		lang_msg: '기본 인터페이스 및 작동 언어를 선택하세요',
		checkversion: '버전 확인',
		currency: '거래 쌍',
		strategy: '전략',
		wangge: '스마트 마틴',
		wangge2: '하단 추적',
		wangge3: '파도 그리드',
		wangge_m: '파도 추세',
		quant: '양자',
		trade: '거래',
		aritcle: '정보',
		my: '나의',
		contract: '계약',
		community_profit: '커뮤니티 수익',
		my_profit: '개인 수익',
		begin_quant: '양화 시작',
		quant_welcome: 'TBA 계약 양화 시스템을 이용해 주셔서 감사합니다',
		myteam: '내 팀',
		follow_setup: '팔로우 설정',
		follow_methods: '팔로우 방법',
		follow_all: '모든 토큰 팔로우',
		token_select_setting: '토큰 설정',
		asset_scale: '순자산 스케일',
		select_trade_strategy: '거래 전략 선택',
		Bill: '계산서',
		transaction: '거래',
		AnnouncementonthelaunchofRainbowQuantitativeRobot: '양화 로봇 출시 공지...',
		AnnouncementonthelaunchofRainbowQuantitativeRobot: '정량로봇 출시 발표…',
	},
	xinzeng: {
		qianbao: "지갑",
		faxian: "발견",
		xiaoxi: "메시지",
		wode: "나의",
		bangdingtibidizhi: "출금 주소를 바인딩하십시오",
		tibidizhi: "출금 주소",
		xuanzebi: "더 많은 토큰 선택",
		tibi: "출금",
		buzhichi: "지원되지 않는 토큰 종류",
		fuzhichengg: "복사 성공",
		chongbi: "입금",
		zichan: "자산",
		exchange_tt: "환전",
		shandui: "플래시 거래",
		zhuanz: "이전",
		zanweikaif: "아직 열리지 않음",
		xi: "가상 은행에 담보로 예치될 예정이며, 상환 시 2주간 잠금 기간이 있습니다.",
		wodezichan: "내 자산",
		keyongzichan: "사용 가능한 자산",
		chankanxiangqing: "자세한 내용 보기",
		chakangengduo: "더보기",
		zhuanzhang: "이체",
		zhiyawakuang: "담보 채굴",
		zhengben: "원장",
		defipool: "채굴 수익률 관리",
		shoukuan: "수금",
		chuangshixinbi: "창시 새로운 코인",
		hongbao: "홍보",
		shengou: "신청서 구매",
		zuixinhangqing: "최신 시세",
		zanweikaitobng: "임시 폐쇄"
	},
	tankuang: {
		no: "취소",
		yes: "확인"
	},
	login: {
		// ----20210409 start---- //
		xiazai: '앱 다운로드',
		welcome: '',
		userLawB: '로그인하면 동의하게 됩니다.',
		userLaw: '《사용자 동의서》',
		PleaseEnterRightPhoneNumber: '올바른 휴대폰 번호를 입력하세요',
		PleaseEnterRightVerificationCode: '올바른 인증 코드를 입력하세요',
		registAccount: '등록 계정',
		PleaseEnterYourPasswordAgain: '비밀번호를 다시 확인해주세요',
		PleaseEnterYourCode: '초대 코드를 입력하세요',
		AgreeLaw: '동의합니다',
		and: '와',
		mianzetiaokuan: '《면책 조항》',
		regist: '등록',
		hasAccount: '계정이 있습니까? 이동',
		// ----20210409 end---- //
		emailorphone: '휴대폰 번호 또는 이메일',
		VerificationCodeLogin: '인증 코드 로그인',
		PasswordToLogin: '비밀번호로 로그인',
		PleaseEnterYourCellPhoneNumber: '휴대폰 번호를 입력하세요',
		PleaseEnterYourCellEmailNumber: '이메일을 입력하세요',
		PleaseEnterVerificationCode: '인증 코드를 입력하세요',
		getCode: '인증 코드',
		login: '로그인',
		login_false: '사용자 이름 또는 비밀번호가 잘못되었습니다',
		login_failed: '인증 코드가 올바르지 않습니다',
		No_register: '먼저 등록하세요',
		WalletUsersLogInDirectly: '지갑 사용자 직접 로그인',
		PleaseEnterYourMobilePhoneNumber: '휴대폰 번호/이메일을 입력해주세요',
		PleaseEnterYourPassword: '비밀번호 (숫자 또는 영문을 8자 이상)를 입력하십시오.',
		RegisteredAccountNumber: '등록된 계정 번호',
		ForgetThePassword: '비밀번호를 잊으셨나요',
		PleaseInputMobilePhoneNumber: '계정을 입력해주세요',
		//핸드폰 번호입력해주세요',
		PleaseInputAPassword: '비밀번호를 입력해주세요',
		PleaseEnterEheVerificationCode: '인증 코드를 입력해주세요',
		MobileRegistration: '휴대폰 등록',
		sidErrTip:'인증 코드 전송에 실패했습니다. 나중에 다시 시도하십시오!',
	},
	// common
	common: {
		home: '홈',
		personal: '개인 센터',
		lang: '언어',
		Chinese: '중국어',
		English: '영어',
		loading: '로딩 중',
		affirm: '확인',
		cancel: '취소'
	},
	// home
	home: {
		MyAssets: '내 자산',
		redPacket: '빨간색 봉투',
		mining: '채굴',
		blotting: '인적 자원',
		Ticket: '티켓',
		college: '학교',
		more: '더보기',
		TPUSparkPlugInSAASMarketplace: 'TPU스파크 플러그인 SAAS마켓플레이스',
		ItCurrently: '현재는 빨간색 봉투 플러그인을 개방하고 있습니다.',
		PartnerRecruitment: '파트너 모집',
		Welcome: '기술, 제품, 운영, 커뮤니티 파트너가 되어 SAAS 플랫폼 구축을 함께 진행해 보세요.'
	},
	liaotian: {
		RedEnvelopeHall: '빨간색 봉투 홀',
		transferAccounts: '계좌 이체',
		redPacket: '빨간색 봉투',
		orderToBetter: '이미 가져감',
		Bidding: '봉투 발송',
		property: '자산',
		GamesArePlayed: '게임 방법',
		unit: '원'
	},
	dig: {
		NervePledgesForMining: 'TPU 스테이킹 마이닝',
		MyPledge: '내 스테이킹',
		ThePledgeToRecord: '스테이킹 기록',
		ToPledge: '스테이킹하러 가기',
		beFrom: '에서',
		greeting: '인사말',
		YouGet: '획득한 보상',
		command: '명령어',
		RedEnvelope: '빨간색 봉투 발송',
		YouHaveOpenedTheRedEnvelope: '빨간색 봉투를 열었습니다',
		OpenRedEnvelope: '빨간색 봉투 열기',
		TurnRedEnvelopes: '빨간색 봉투 전환',
		PleaseEnterRedEnvelope: '빨간색 봉투 명령어를 입력하세요',
		altogether: '총',
		individual: '개',
		HaveBeenReceiving: '이미 수령함',
		snag: '획득',
		phone: "핸드폰 번호를 등록하세요",
		password: "거래 비밀번호를 설정하세요"
	},
	fahongbao: {
		SendingRedEnvelope: '빨간봉투 보내기',
		NeedAPassword: '비밀번호 필요',
		need: '필요',
		withNoNeed: '필요없음',
		SetThePassword: '비밀번호 설정',
		ThroughTheType: '토큰 유형',
		BonusAmount: '빨간봉투 금액',
		pleaseEnter: '입력해주세요',
		TheNumberOfRedPackets: '빨간봉투 수',
		redEnvelopeType: '빨간봉투 종류',
		random: '랜덤',
		goHalves: '공동분담',
		CoverGreetings: '봉투 위 문구',
		serviceCharge: '서비스 수수료',
		WhetherThePublic: '공개 여부',
		open: '공개',
		privacy: '비공개',
		balance: '잔액',
		NotEnvelope: '빨간봉투 금액이 부족합니다',
		Recharge: '충전',
		GenerateRedEnvelope: '빨간봉투 생성',
		MyRecord: '내 기록',
		ContactUs: '고객 센터 연락처',
		PleaseEnterTradingPassword: '비밀번호를 입력해주세요',
		SixPassword: '6자리 숫자만 입력 가능합니다',
		PleaseEnterSixTransactionPassword: '6 자리 숫자의 거래 비밀번호를 입력하세요',
		hint: '알림',
		InsufficientBalancePlease: '잔액이 부족합니다',
		PleaseEnterYourPassword: '비밀번호를 입력해주세요',
		PleaseSelectThePass: '토큰을 선택해주세요',
		totalAmountCannotBeEmpty: '빨간봉투 총액은 비워둘 수 없습니다',
		numberOfRedEnvelopesCannotBeEmpty: '빨간봉투 수는 비워둘 수 없습니다',
		PleaseEnterYourUsernameAndPassword: '사용자 이름과 비밀번호를 입력해주세요',
		affirm: '확인',
		cancel: '취소'
	},
	coffer: {
		MyAssets: '내 자산',
		cloudAssets: '클라우드 자산',
		availableCapital: '사용 가능한 자산',
		totalAssets: '총 자산',
		frozenAssets: '동결된 자산',
		withdraw: '인출',
		LocalAssets: '지역 자산',
		FunctionalIntegration: '기능 통합 중'
	},
	explain: {
		GameShows: '게임 방식 설명',
		PasswordRedEnvelope: '비밀번호 빨간색 봉투: 사용자가 올바른 비밀번호를 입력해야 봉투를 열 수 있습니다.',
		OrdinaryHongbao: '보통 빨간색 봉투: 선착순',
		UnloggedUsers: '로그인하지 않은 사용자는 휴대폰 번호와 인증 코드를 입력해야 빨간색 봉투를 받을 수 있습니다.',
		RedEnvelopeIsALightweight: '빨간색 봉투는 경량 IM입니다. IM 기반 상호 작용 방법을 기대해주세요.'
	},
	myPledge: {
		MyPledge: '내 담보',
		operate: '조작',
		AdditionalMortgage: '추가 담보',
		pledgeToRedeem: '담보 해제',
		ExpectedEarnings: '예상 수익',
		pledgeNumber: '담보 수량',
		accumulatedEarnings: '누적 수익',
		pledgeOfDays: '담보 일수',
		applicationDate: '신청 날짜',
		Number: '수량',
		currency: '화폐 종류',
		status: '상태',
		packUp: '접기',
		NoPledgeHasBeenCompleted: '담보 완료 시간은 24 시간입니다.',
		PleaseEnterTransactionPassword: '거래 비밀번호를 입력해주세요.',
		PleaseEnterAdditionalQuantity: '추가 수량을 입력해주세요.',
		SuccessfulPledgeRedemption: '담보 해제 성공',
		gainRecording: '수익 받기',
		todayEarnings: '예상 일일 수익'
	},
	redemptionRecord: {
		pledgeToRecord: '담보 기록',
		NoMore: '이제 더 이상 없습니다.',
		serialNumber: '일련번호',
		operate: '조작',
		Number: '수량',
		status: '상태',
		IsThePledge: '담보 중',
		BeganToYield: '수익 시작',
		RedemptionComplete: '해제 완료',
		ApplicationDate: '신청 날짜',
		ReturnsTheTime: '수익 시간'
	},
	pledge: {
		DigTheNumber: '채굴 수량',
		SinceThe50: '개 이상',
		all: '전체',
		usable: '사용 가능',
		HaveAlreadyPledged: '이미 토큰을 예치함',
		DueOnDemand: '수시 입출금',
		predict: '예상',
		StartCalculatingReturns: '수익 계산 시작',
		ExpectedMonthlyEarnings: '예상 연간 수익',
		ToPledge: '예치하러 가기',
		PleaseEnterTransactionPassword: '거래 비밀번호를 입력하세요',
		pledgeSuccess: '예치 성공'
	},
	track: {
		blotting: '인적 사항',
		BlockchainSourceTrackingCardStoragePlatform: '블록체인 원산지 추적 보관 플랫폼',
		ProductDevelopmentAndDebuggingInProgress: '제품 개발 및 디버깅 진행 중'
	},
	ticket: {
		ticketService: '티켓',
		LetDigital: '디지털 자산으로 티켓 구매 지원, 예를 들어 기차표, 항공표, 전화 카드, 주유 카드 등',
		SupportNULS: 'NULS, TPU, ETH 등을 지원합니다',
		SpecialSupportForPerformance: '공연 티켓을 특별히 지원하여 디지털 티켓의 빠른 발급 및 유통을 지원합니다. 공연 티켓 구매가 필요한 경우 함께 상의해 보세요',
		SupportForTrainTickets: '기차표, 항공표 등의 지원은 이전 버전에서 이전되는 중입니다'
	},
	tpuschool: {
		BlockchainCorrelation: '블록체인 관련',
		learningMaterials: '학습 자료',
		caseTeaching: '사례 기반 교육',
		solution: '해결책',
		InCollectingData: '자료 수집 중'
	},
	more: {
		more: '더 보기',
		SupportTheSpread: '진실하고 유효한 음성의 전파를 지원합니다',
		SupportThePromotion: '선도 기술과 문화의 홍보를 지원합니다',
	},
	geren: {
		change: "언어 변경",
		personalCenter: '개인 센터',
		MyAssets: '내 자산',
		redEnvelopeToRecord: '빨간 봉투 기록',
		MentionFillingRecord: '입출금 기록',
		gainRecording: '수익 기록',
		personalSetting: '개인 설정',
		InviteRewards: '초대 보상',
		IWantToFeedback: '피드백 제출',
		switchAccount: '계정 전환',
		logOut: '로그아웃',
		phoneNumber: '전화번호',
		email: '이메일 주소',
		site: '주소',
		cashFlow: '현금 흐름'
	},
	redRecode: {
		redRecode: '빨간색 봉투 기록',
		ISent: '내가 보낸',
		NoMore: '더 이상 없음',
		redPacket: '빨간색 봉투',
		individual: '개',
		get: '받기',
		status: '상태',
		InTheDistributed: '배포 중',
		HasRecycled: '재활용 중',
		time: '시간',
		operation: '조작',
		examine: '검사',
		withdraw: '철수',
		IReceivedThe: '내가 받은',
		hint: '힌트',
		WithdrawTheSuccess: '성공적으로 철수되었습니다'
	},
	cashOut: {
		MentionFillingRecord: '충전 기록',
		NoMore: '더 이상 없음',
		serialNumber: '일련 번호',
		token: '토큰',
		number: '수량',
		time: '시간',
		WithdrawalRecord: '출금 기록',
		status: '상태',
		clickToView: '클릭하여보기'
	},
	shouyiRecord: {
		gainRecording: '수입 기록',
		NoMore: '더 이상 없음',
		operation: '조작',
		serialNumber: '일련 번호',
		principal: '주된',
		rateInterest: '이자율',
		earnings: '수익',
		data: '데이트',
		GetBenefits: '수익을 얻으세요',
		GetTheSuccess: '성공적으로 수집했습니다'
	},
	Uset: {
		set: '설정',
		SetCellPhoneNumber: '휴대 전화 바인딩',
		SetEmail: '이메일 바인딩',
		updateEmail: '이메일 수정',
		email: '이메일',

		loginPassword: '로그인 비밀번호',
		transactionPassword: '거래 비밀번호',
		nicknameIsSet: '닉네임 설정',
		PleaseEnterNewNickname: '새로운 닉네임을 입력해주세요',
		PictureUpload: '프로필 사진 업로드',
		affirm: '확인',
		cancel: '취소'
	},
	sPhone: {
		email: '이메일 주소',
		phoneNumber: '휴대폰 번호',
		PleaseEnterYourCellPhoneNumber: '휴대폰 번호를 입력해주세요',
		messageAuthenticationCode: '인증 코드',
		pleaseMessageAuthenticationCode: '인증 코드를 입력해주세요',
		getCode: '인증 코드 받기',
		SetCellPhoneNumber: '휴대폰 번호 바인딩',
		SetEmail: '이메일 바인딩',
		YouNeed: '인출 및 거래를 위해서는 정확한 휴대폰 번호가 필요합니다',
		WrongCellPhoneNumber: '휴대폰 번호가 잘못되었습니다',
		verificationCode: '휴대폰 인증 코드를 입력해주세요',
		phoneNumberHasBeenSetSuccessfully: '휴대폰 번호 설정 완료'
	},
	sPwd: {
		SetTradePassword: '로그인 비밀번호 설정',
		messageAuthenticationCode: '인증 코드',
		PleaseVerificationCode: '인증 코드를 입력해주세요',
		getCode: '인증 코드 받기',
		newPassword: '새 비밀번호',
		pleaseNewPassword: '새 비밀번호를 입력해주세요',
		affirmPassword: '비밀번호 확인',
		PleaseEnterPasswordAgain: '비밀번호를 다시 입력해주세요',
		setPassword: '비밀번호 설정',
		SMSVerificationCodeCannotBeEmpty: '인증 코드를 입력해주세요',
		pleaseAffirmPassword: '비밀번호를 확인해주세요',
		twoPasswordsDoNotMatch: '비밀번호가 일치하지 않습니다',
		setPasswordSuccess: '비밀번호 설정 완료',
		email: '이메일 주소',
		phoneNumber: '휴대폰 번호'
	},
	sPay: {
		TradePasswordBinding: '거래 비밀번호 바인딩',
		messageAuthenticationCode: '인증 코드',
		PleaseSMSVerificationCode: '인증 코드를 입력하세요',
		getCode: '인증 코드 받기',
		TradePassword: '거래 비밀번호를 입력하세요',
		pleaseEnterTradePassword: '거래 비밀번호를 입력하세요',
		ConfirmTransactionPassword: '비밀번호 확인',
		pleaseAffirmTradePassword: '거래 비밀번호를 다시 한번 확인하세요',
		BindingTradePassword: '거래 비밀번호 바인딩',
		PleaseEnterCorrectVerificationCode: '올바른 인증 코드를 입력하세요',
		PleaseEnterCorrectTransactionPassword: '올바른 거래 비밀번호를 입력하세요',
		PleaseEnterCorrectTransactionEmail: '올바른 이메일을 입력하세요',
		emailSame: '새 이메일과 원래의 이메일이 중복된다',
		twoPasswordsDoNotMatch: '비밀번호가 일치하지 않습니다',
		hint: '알림',
		TransactionPasswordChangedSuccessfully: '거래 비밀번호 변경 성공',
		affirm: '확인',
		email: '이메일 주소',

		phoneNumber: '휴대폰 번호'
	},
	team: {
		InviteRewards: '초대 보상',
		MyInvitation: '내 초대',
		InviteLink: '초대 링크',
		InviteCode: '초대 코드',
		CopyInvitationAddress: '초대 주소 복사',
		CopyInvitationCode: '초대 코드 복사',

		FaceFaceInvitation: '면대면 초대',
		SweepCodeToInvite: '코드를 스캔하여 초대',
		MyReward: '나의 보상',
		InviteNumber: '초대 인원',
		InviteReward: '초대 보상',
		rankingList: '랭킹 리스트',
		redPacket: '빨간색 패킷',
		individual: '개인',
		time: '시간',
		hint: '알림',
		affirm: '확인',
		copySuccess: '복사 완료'
	},
	feedback: {
		coupleBack: '피드백',
		WelcomeCommunicateWithTheTeam: '팀과의 소통을 환영합니다',
		PleaseEnterTheCommunicationItems: '의사 소통 항목을 입력하세요',
		ImmediatelySend: '즉시 전송',
		FeedbackContentCannotBeEmpty: '피드백 내용을 입력하세요',
		FeedbackSuccess: '피드백 성공'
	},
	cashFlow: {
		cashFlow: '현금 유동성',
		NoMore: '끝',
		serialNumber: '일련 번호',
		operate: '조작',
		flow: '유동성',
		data: '날짜',
		GetTheSuccess: '성공적으로 받았습니다'
	},
	share: {
		laizi: "보낸 사람",
		de: "의 레드 패킷",
		zong: "총액",
		chai: "패킷 열기",
		fas: "친구에게 보내기",
		baocun: "공유 이미지로 저장",
		chang: "위의 그림을 길게 누르세요. 로컬에 저장"
	},
	recharge: {
		one: "입금 주소",
		min: "최소 입금액",
		fuzhi: "주소 복사"
	},
	tixian: {
		shenqing: "인출 신청",
		one: "인출 토큰",
		two: "사용 가능한 수량",
		dongjie: "동결 수량",
		duanxin: "인증 코드",
		tixian: "인출 수량",
		jiaoyi: "거래 비밀번호",
		fuwu: "수수료 선택",
		bangding: "인출 주소 바인딩",
		fasong: "인증 코드 보내기",
		fasongqingiqu: "요청 보내기",
		qingone: "인증 코드를 입력하십시오",
		qingtwo: "인출 수량을 입력하십시오",
		qingthree: "거래 비밀번호를 입력하십시오",
		tanone: "인출 수량은 최소 인출 수량보다 작아야합니다",
		tantwo: "인출 수량은 0보다 커야합니다",
		tanthree: "인출 수량이 사용 가능한 잔액보다 큽니다",
		tanfour: "인출",
		tanfive: "먼저 인출 주소를 설정하십시오",
		tansix: "인출 성공, 24 시간 이내에 처리됩니다",
		tansevw: "먼저 거래 비밀번호를 설정하십시오",
		zuidi: "최소 인출 금액",
		keyi: "인출 가능 금액",
		fuwufei: "서비스 수수료",
		shoudao: "받은 금액：",
		yzmerr: "인증 코드가 잘못되었습니다",
		pwderr: "거래 비밀번호가 잘못되었습니다",
		tberr: "인출 실패"
	},
	bangdingdizhi: {
		one: "주소 바인딩",
		two: "인증 코드",
		three: "인증 코드를 입력하세요",
		huoquyanzhnegm: "인증 코드 받기",
		jiaoyi: "거래 비밀번호",
		qingshuru: "거래 비밀번호를 입력하세요",
		tixian: "출금 주소",
		qingtwo: "바인딩할 주소를 입력하세요",
		dizhi: "주소 메모",
		qingfour: "주소 메모를 입력하세요",
		bangdingdizhi: "주소 바인딩",
		tanone: "인증 코드는 비워 둘 수 없습니다.",
		tantwo: "주소를 입력하세요",
		tanthree: "주소 메모를 입력하세요",
		yes: "바인딩 성공",
	},
	phoneCode: {
		A: ['아프가니스탄 +93+4', '알바니아 +355+8', '알제리 +213+12', '미국령 사모아 +684+16', '안도라 +376+20', '앙골라 +244+24', '앵귈라 +1264+660', '앤티가 바부다 +1268+28', '아르헨티나 +54+32', '아르메니아 +374+51', '오스트레일리아 +61+36', '오스트리아 +43+40', '아제르바이잔 +994+31'],
		B: ['바하마 +1242+44', '바레인 +973+48', '방글라데시 +880+50', '바베이도스 +1246+52', '벨라루스 +375+112', '벨기에 +32+56', '벨리즈 +501+84', '베냉 +229+204', '버뮤다 제도 +1441+60', '볼리비아 +591+68', '보츠와나 +267+72', '브라질 +55+76', '브루나이 +673+96', '불가리아 +359+100', '부르키나 파소 +226+854', '부룬디 +257+108'],
		C: ['캄보디아 +855+116', '카메룬 +237+120', '캐나다 +1+124', '케이맨 제도 +1345+136', '중앙 아프리카 공화국 +236+140', '차드 +235+148', '칠레 +56+152', '중국 +86+156', '콜롬비아 +57+170', '콩고 +242+178', '쿡 제도 +682+184', '코스타리카 +506+188', '코트디부아르 +225+384', '쿠바 +53+192', '키프로스 +357+196', '체코 공화국 +420+203'],
		D: ['덴마크 +45+208', '지부티 +253+262', '도미니카 공화국 +1890+214'],
		E: ['에콰도르 +593+218', '이집트 +20+818', '엘살바도르 +503+222', '에스토니아 +372+233', '에티오피아 +251+231'],
		F: ['피지 제도 +679+242', '핀란드 +358+246', '프랑스 +33+250', '프랑스령 기아나 +594+254', '프랑스령 폴리네시아 +689+258'],
		G: ['가봉 +241+266', '감비아 +220+270', '조지아 +995+268', '독일 +49+276', '가나 +233+288', '지브롤터 +350+292', '그리스 +30+300', '그레나다 +1809+308', '괌 +1671+316', '과테말라 +502+320', '기니 +224+324', '가이아나 +592+328'],
		G: ['가봉 +241+266', '감비아 +220+270', '조지아 +995+268', '독일 +49+276', '가나 +233+288', '지브롤터 +350+292', '그리스 +30+300', '그레나다 +1809+308', '괌 +1671+316', '과테말라 +502+320', '기니 +224+324', '가이아나 +592+328'],
		H: ['아이티 +509+332', '온두라스 +504+340', '홍콩 +852+344', '헝가리 +36+348'],
		I: ['아이슬란드 +354+352', '인도 +91+356', '인도네시아 +62+360', '이란 +98+364', '이라크 +964+368', '아일랜드 +353+372', '이스라엘 +972+376', '이탈리아 +39+380'],
		J: ['자메이카 +1876+388', '일본 +81+392', '요르단 +962+400'],
		K: ['카자흐스탄 +7+398', '케냐 +254+404', '조선 +850+408', '대한민국 +82+410', '쿠웨이트 +965+414', '키르기스스탄 +331+417'],
		L: ['라오스 +856+418', '라트비아 +371+428', '레바논 +961+422', '레소토 +266+426', '라이베리아 +231+430', '리비아 +218+434', '리히텐슈타인 +423+438', '리투아니아 +370+440', '룩셈부르크 +352+442', '르완다 +250+646'],
		M: ['마카오 +853+446', '마다가스카르 +261+450', '말라위 +265+454', '말레이시아 +60+458', '몰디브 +960+462', '말리 +223+466', '몰타 +356+470', '마르티니크 +596+474', '모리셔스 +230+480', '멕시코 +52+484', '몰도바 +373+498', '모나코 +377+492', '몽골 +976+496', '몬트세랫 +1664+500', '모로코 +212+504', '모잠비크 +258+508', '미얀마 +95+104'],
		N: ['나미비아 +264+516', '나우루 +674+520', '네팔 +977+524', '네덜란드 +31+528', '네덜란드령 안틸레스 +599+530', '뉴질랜드 +64+554', '니카라과 +505+558', '니제르 +227+562', '나이지리아 +234+566', '노르웨이 +47+578'],
		O: ['오만 +968+512'],
		P: ['파키스탄 +92+586', '파나마 +507+591', '파푸아뉴기니 +675+598', '파라과이 +595+600', '페루 +51+604', '필리핀 +63+608', '폴란드 +48+616', '포르투갈 +351+620', '푸에르토리코 +1787+630'],
		Q: ['카타르 +974+634'],
		R: ['레위니옹섬 +262+638', '루마니아 +40+642', '러시아 +7+643'],
		S: ['세인트루시아 +1758+662', '세인트빈센트 그레나딘 +1784+670', '사모아 +685+882', '산마리노 +378+674', '상투메 프린시페 +239+678', '사우디아라비아 +966+682', '세네갈 +221+686', '세이셸 +248+690', '시에라리온 +232+694', '싱가포르 +65+702', '슬로바키아 +421+703', '슬로베니아 +386+705', '솔로몬 제도 +677+90', '소말리아 +252+706', '남아프리카 공화국 +27+710', '스페인 +34+724', '스리랑카 +94+144', '수단 +249+736', '수리남 +597+740', '스와질란드 +268+748', '스웨덴 +46+752', '스위스 +41+756', '시리아 +963+760'],
		T: ['대만 +886+158', '타지키스탄 +992+762', '탄자니아 +255+834', '태국 +66+764', '토고 +228+768', '통가 +676+776', '트리니다드 토바고 +1809+780', '튀니지 +216+788', '터키 +90+792', '투르크메니스탄 +993+795'],
		U: ['우간다 +256+800', '우크라이나 +380+804', '아랍 에미리트 연합 +971+784', '영국 +44+826', '미국 +1+840', '우루과이 +598+858', '우즈베키스탄 +998+860'],
		V: ['베네수엘라 +58+862', '베트남 +84+704'],
		Y: ['예멘 +967+887'],
		Z: ['잠비아 +260+894', '짐바브웨 +263+716'],
	}
}
